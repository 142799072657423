import { Properties } from "@camberi/firecms";
import SlugTextField from "../customFields/slugField";

export const translationProperties: Properties<any> = {
    de: {
        dataType: "string",
        title: "German"
    },
    en: {
        dataType: "string",
        title: "English"
    },
    es: {
        dataType: "string",
        title: "Español"
    }    
};

export const translationContentProperties: Properties<any> = {
    de: {
        title: "German",
        dataType: "array",
        columnWidth: 400,
        oneOf: {
            properties: {
                images: {
                    title: "Images",
                    dataType: "array",
                    of: {
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "images",
                                acceptedFiles: ["image/*"],
                                metadata: {
                                    cacheControl: "max-age=1000000"
                                }
                            }
                        }
                    },
                    description: "This fields allows uploading multiple images at once and reordering"
                },
                test: {
                    dataType: "string",
                    title: "Test"
                },
                text: {
                    dataType: "string",
                    title: "Text",
                    config: {
                        markdown: true
                    }
                },
            }
        }
    },
    en: {
        title: "English",
        dataType: "array",
        columnWidth: 400,
        oneOf: {
            properties: {
                images: {
                    title: "Images",
                    dataType: "array",
                    of: {
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "images",
                                acceptedFiles: ["image/*"],
                                metadata: {
                                    cacheControl: "max-age=1000000"
                                }
                            }
                        }
                    },
                    description: "This fields allows uploading multiple images at once and reordering"
                },
                test: {
                    dataType: "string",
                    title: "Test"
                },
                text: {
                    dataType: "string",
                    title: "Text",
                    config: {
                        markdown: true
                    }
                },
            }
        }
    },
    es: {
        title: "Spanish",
        dataType: "array",
        columnWidth: 400,
        oneOf: {
            properties: {
                images: {
                    title: "Images",
                    dataType: "array",
                    of: {
                        dataType: "string",
                        config: {
                            storageMeta: {
                                mediaType: "image",
                                storagePath: "images",
                                acceptedFiles: ["image/*"],
                                metadata: {
                                    cacheControl: "max-age=1000000"
                                }
                            }
                        }
                    },
                    description: "This fields allows uploading multiple images at once and reordering"
                },
                test: {
                    dataType: "string",
                    title: "Test"
                },
                text: {
                    dataType: "string",
                    title: "Text",
                    config: {
                        markdown: true
                    }
                },
            }
        }
    }
}

export const translationMultilineProperties: Properties<any> = {
    de: {
        dataType: "string",
        title: "German",
        config: {
            multiline: true
        }
    },
    en: {
        dataType: "string",
        title: "English",
        config: {
            multiline: true
        }
    },
    es: {
        dataType: "string",
        title: "Español",
        config: {
            multiline: true
        }
    }    
};

export const translationSlugProperties: Properties<any> = {
    de: {
        dataType: "string",
        title: "German",
        config: {
            Field: SlugTextField
        }
    },
    en: {
        dataType: "string",
        title: "English",
        config: {
            Field: SlugTextField
        }
    },
    es: {
        dataType: "string",
        title: "Español",
        config: {
            Field: SlugTextField
        }
    }    
};