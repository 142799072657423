import React from "react";

import { User as FirebaseUser } from "firebase/auth";
import logo from "./images/logo.svg";
import {
  Authenticator,
  buildCollection,
  FirebaseCMSApp,
  NavigationBuilder,
  NavigationBuilderProps
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import { qaSchema } from "./schemas/q_a";
import { categorySchema } from "./schemas/categories";

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyCWmvltQpCxOWJQQ8H55BM-kskNfXOr9H0",
  authDomain: "help-center-8f7af.firebaseapp.com",
  projectId: "help-center-8f7af",
  storageBucket: "help-center-8f7af.appspot.com",
  messagingSenderId: "378674377106",
  appId: "1:378674377106:web:28732b37903f2c74fd914b",
  measurementId: "G-EWV68MXFNW"
};



export default function App() {

  const navigation: NavigationBuilder = async ({
    user,
    authController
  }: NavigationBuilderProps) => {

    return ({
      collections: [
        
        buildCollection({
          path: "qa",
          schema: qaSchema,
          name: "Questions & Answers",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          })
        }),
        buildCollection({
          path: "cat",
          schema: categorySchema,
          name: "Categories",
          permissions: ({ authController }) => ({
            edit: true,
            create: true,
            // we have created the roles object in the navigation builder
            delete: authController.extra.roles.includes("admin")
          }),
        }),
      ]
    });
  };

  const myAuthenticator: Authenticator<FirebaseUser> = async ({
    user,
    authController
  }) => {
    // You can throw an error to display a message
    if (user?.email?.includes("flanders")) {
      throw Error("Stupid Flanders!");
    }

    console.log("Allowing access to", user?.email);
    // This is an example of retrieving async data related to the user
    // and storing it in the user extra field.
    const sampleUserData = await Promise.resolve({
      roles: ["admin"]
    });
    authController.setExtra(sampleUserData);
    return true;
  };

  return <FirebaseCMSApp
    logo={logo}
    name={"Medicalmotion Help Center"}
    authentication={myAuthenticator}
    navigation={navigation}
    firebaseConfig={firebaseConfig}
    primaryColor={"#03238d"}
    secondaryColor={"#8AC9BD"}
  />;
}