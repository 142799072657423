import React from "react";
import { TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { FieldDescription, FieldProps } from "@camberi/firecms";


export default function SlugTextField({
                                                 property,
                                                 value,
                                                 setValue,
                                                 customProps,
                                                 touched,
                                                 error,
                                                 isSubmitting,
                                                 context, // the rest of the entity values here
                                                 ...props
                                             }: FieldProps<string>) {


    return (
        <>
            <TextField required={property.validation?.required}
                       error={!!error}
                       disabled={isSubmitting}
                       label={property.title}
                       value={value ?? ""}
                       onChange={(evt: any) => {
                           var string = (evt.target.value as string).toLowerCase().replace(/ /g, '-')
                           .replace(/[^\w-]+/g, '');
                           setValue(
                            string
                           );
                       }}
                       helperText={error}
                       fullWidth
                       variant={"filled"}/>

            <FieldDescription property={property}/>
        </>

    );

}