import { buildProperty, buildSchema, Locale } from "@camberi/firecms";
import SlugTextField from "../customFields/slugField";
import { translationMultilineProperties, translationProperties, translationSlugProperties } from "./common";

const locales = {
    "en-US": "English (United States)",
    "es-ES": "Spanish (Spain)",
    "de-DE": "German"
  };

export const categorySchema = buildSchema({
    name:"Categories",
    properties: {
        name: buildProperty({
            title: "Name",
            validation: { required: true },
            dataType: "map",
            properties: translationProperties,
        }),
        parent_category: buildProperty({
            title: "Parent Category",
            dataType: "reference",
            path: "cat",
            previewProperties: ["name"]
        }),
        slug: {
            title: "Slug",
            validation: { required: true },
            dataType: "map",
            properties: translationSlugProperties
        },
        description: {
            title: "Description",
            validation: { required: true },
            dataType: "map",
            properties: translationMultilineProperties
        },
        image: {
            dataType: "string",
            title: "Image",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "action-habits",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        cacheControl: "max-age=1000000"
                    }
                }
            },
            description: "Main image for the action/habit"
        },
    }
})
