import { buildSchema, buildProperty, ExportMappingFunction } from "@camberi/firecms";
import { BlogEntryPreview } from "../blocks/BlogEntryPreview";
import SlugTextField from "../customFields/slugField";
import { translationContentProperties, translationMultilineProperties, translationProperties, translationSlugProperties } from "./common";

/**
 * This is a schema that is generated without passing an explicit type,
 * but it is inferred correctly since we are using `buildProperty` in each property
 */
export const qaSchema = buildSchema({
    name: "Q&A entry",
    
    properties: {
        category: buildProperty({
            title: "Category",
            dataType: "reference",
            path: "cat",
            previewProperties: ["name"]
        }),
        slug: buildProperty({
            title: "Slug",
            description: "the name of the page visible in the URL address ex. https://helpcenter.medicalmotion.com/en/exercises/how_to_do_that",
            validation: { required: true },
            dataType: "map",
            properties: translationSlugProperties,
        }),
        question: {
            title: "Question",
            validation: { required: true },
            dataType: "map",
            properties: translationProperties
        },
        answer: buildProperty({
            title: "Answer",
            description: "Articulate the answer to the main question",
            validation: { required: true },
            dataType: "map",
            properties:translationContentProperties,
            columnWidth: 400,
        }),
        publish_date: buildProperty({
            title: "Publish date",
            dataType: "timestamp"
        }),
        reviewed: buildProperty({
            title: "Reviewed",
            dataType: "boolean"
        }),
        status: buildProperty({
            title: "Status",
            validation: { required: true },
            dataType: "string",
            columnWidth: 140,
            config: {
                enumValues: {
                    published: "Published",
                    draft: "Draft"
                }
            }
        }),
        views: {
            dataType: "number",
            title: "Views",
            readOnly: true
        },
        useful: {
            dataType: "number",
            title: "Useful",
            readOnly: true
        },
        not_useful: {
            dataType: "number",
            title: "Not Useful",
            readOnly: true
        },
        seo_title: buildProperty({
            title: "SEO Title",
            validation: { required: true },
            dataType: "map",
            properties: translationProperties
        }),
        seo_description: buildProperty({
            title: "SEO Description",
            validation: { required: true },
            dataType: "map",
            properties: translationMultilineProperties
        }),
        tags: buildProperty({
            title: "Tags",
            description: "Example of generic array",
            dataType: "array",
            of: {
                dataType: "string",
                config: {
                    previewAsTag: true
                }
            }
        })
    },
    defaultValues: {
        status: "draft",
        tags: ["default tag"]
    }
});

/**
 * Sample field that will be added to the export
 */
export const sampleAdditionalExportColumn: ExportMappingFunction = {
    key: "extra",
    builder: async ({ entity }) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return "Additional exported value " + entity.id;
    }
};